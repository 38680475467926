.homepage {
	background-color: transparent !important;
	.row {
		margin-bottom: 0;
	}
	h2{
		font-size: 3rem;
	}

	.section {
		background-color: rgb(var(--primary-color));
	}

	.heading {
		position: absolute;
		top: 20%;
		color: grey;
		mix-blend-mode: difference;
		right: 50%;
		transform: translateX(50%);
		font-size: 3rem;
		font-weight: 500;
		text-align: center;
	}
	.parallax-container{
		height: 35vh;
	}
	.parallax-container:first-of-type{
		height: 50vh;
	}
	.text-center{
		text-align: center;
	}
	.text-right{
		text-align: right;
	}
	.short-container{
		height: 200px;
	}
	.ultra-short-container{
		height: 150px;
	}
	.slogan{
		font-size: 1.4rem;
	}
	a{
		color: rgb(var(--text-color));
		text-decoration: underline;
	}
}