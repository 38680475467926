/* to make sidebar work */

main, footer, .navbar {
	padding-left: var(--sidebar-width);
}

@media only screen and (max-width: 992px) {
	main, footer, .navbar {
		padding-left: 0;
	}
	.navbar .brand-logo {
		left: 130px; 	
	}
}

html {
	height: 100vh;
}

.app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	color: rgb(var(--text-color));
	/* background: rgb(var(--background-color)); */
}

main{
	flex: 1 1 auto;
	transition: .5s;
}

.info-box{
	width: 100%;
	text-align: center;
	background-color: rgba(var(--secondary-color), 0.9);
	padding: 10px;
	border-radius: 30px;
	margin: 20px 0;
}

.color-mode-dark img{
	filter: brightness(0.6) sepia(.1) saturate(1.2);
}