// @import "/node_modules/react-vis/;
@import "~react-vis/dist/style";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --sidebar-width: 250px;
}

main{
  background-color: rgb(var(--background-color));
  z-index: 0;
  word-wrap: break-word;
}

.separator, .separator-soft{
  width: 100%;
  height: 1px;
  background-color: rgb(var(--text-color));
  margin: 5px 0;
}
.separator-soft{
  background: linear-gradient(90deg, rgb(var(--background-color)) 10%, rgb(var(--text-color)) 50%, rgb(var(--background-color)) 90%);
}

.hidden{
  display: none;
}

video, img{
  width: clamp(50px, 100%, 100%);
}

.page:last-child{
  margin-bottom: 30px;
}


.tile-collection{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  position: relative;
  max-height: 1000vh;
  transition: .75s;
}
.tile-collection .tile{
  border: 1px solid rgb(var(--text-color));
  padding: .5em;
  border-radius: 10px;
  width: clamp(200px, 30%, 500px);
  margin: 15px 10px;
  .heading{
    font-size: larger;
  }
  .details-page{
    display: flex;
    justify-content: center;
  }
}

.alert-box{
  border: 1px solid rgb(212, 36, 36);
  border-radius: 10px;
  padding: 10px;
  margin: 2px 0;
  text-align: center;
  font-size: 120%;
  h5{
    margin-top: 5px;
  }
}

.boxed{
  border: 1px solid rgb(var(--text-color));
  border-radius: 10px;
}